import { Children, cloneElement } from "react";
import { Create, Datagrid, Edit, EditButton, EmailField, List, PasswordInput, RadioButtonGroupInput, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TextInput } from "react-admin";
import { FileBrowser, useFileBrowser } from "./FileBrowser";

const PostListActionToolbar = ({ children, ...props }) => {
    return (
        <div>
            {Children.map(children, button => cloneElement(button, props))}
        </div>
    );
};

export const UserList = props => (
    <List {...props} exporter={false}>
        <Datagrid /*rowClick={(id, basePath, record) => console.log(id, basePath, record)}*/>
            <TextField source="id" />
            <TextField source="name" />
            <EmailField source="email" />
            {/* <TextField source="email_verified_at" /> */}
            <TextField source="role" />

            <PostListActionToolbar>
                <EditButton />
                <ShowButton />
            </PostListActionToolbar>
        </Datagrid>
    </List>
);

export const UserCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="email" />
            <PasswordInput source="password" />
            <RadioButtonGroupInput
                source="role"
                choices={[
                    { id: 'manager', name: 'Manager' },
                    { id: 'employee', name: 'Employee' },
                    { id: 'costumer', name: 'Costumer' },
                ]}
                defaultValue="costumer"
            />
        </SimpleForm>
    </Create>
);

export const UserEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            {/* <TextInput disabled source="id" /> */}
            <TextInput source="name" />
            <TextInput source="email" />
            <RadioButtonGroupInput
                source="role"
                choices={[
                    { id: 'manager', name: 'Manager' },
                    { id: 'employee', name: 'Employee' },
                    { id: 'costumer', name: 'Costumer' },
                ]}
            />
        </SimpleForm>
    </Edit>
);

export const UserShow = props => {
    const {
      files,
      isFirstLoaded,
      loading,
      handleCreateFolder,
      handleCreateFile,
      handleDeleteItem,
      handleSendFile,
    } = useFileBrowser(props.id);
    // TODO: validare se props.id è corretto

    return (
        <Show {...props}>
            <SimpleShowLayout>
                {/* <TextField source="id" /> */}
                <TextField source="name" />
                {(!isFirstLoaded || loading) && (
                    <h3>Loading folders...</h3>
                )}
                {isFirstLoaded && (
                    <div className={"col-12 mt-4" + (!loading ? ' d-block' : ' d-none')}>
                        <div style={{ height: 'calc(100vh - 300px)' }}>
                            <FileBrowser
                                handleCreateFolder={handleCreateFolder}
                                handleCreateFile={handleCreateFile}
                                handleDeleteItem={handleDeleteItem}
                                customActions={[{
                                    callback: (path) => {
                                        handleSendFile(path)
                                    }
                                }]}
                                files={files}

                                // onCreateFolder={handleCreateFolder}
                                // onCreateFiles={handleCreateFiles}
                                // onMoveFolder={handleRenameFolder}
                                // onMoveFile={handleRenameFile}
                                // onRenameFolder={handleRenameFolder}
                                // onRenameFile={handleRenameFile}
                                // onDeleteFolder={handleDeleteFolder}
                                // onDeleteFile={handleDeleteFile}
                            />
                        </div>
                    </div>
                )}
            </SimpleShowLayout>
        </Show>
    );
}