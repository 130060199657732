import { DataProvider, fetchUtils, GetManyResult } from 'react-admin';
import { Constants } from './app-lib/constants';
// import { stringify } from 'query-string';

const apiUrl = Constants.API.BASEURL[Constants.API.ENV];
// const httpClient = fetchUtils.fetchJson;
const httpClient = (url: string, options = {}) => {
    if (!(options as any).headers) {
        (options as any).headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('access_token');
    (options as any).headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
}

const dataProvider: DataProvider = {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}${resource}` // + `?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.results,
            total: parseInt(headers.get('X-Total-Count') || '', 10),
        }));
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}${resource}/${params.id}`).then(({ json }) => ({
            data: json.results,
        })),

    getMany: (resource, params) => {
        return Promise.resolve({} as any)
    //     const query = {
    //         filter: JSON.stringify({ id: params.ids }),
    //     };
    //     const url = `${apiUrl}${resource}?${stringify(query)}`;
    //     return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        return Promise.resolve({} as any)
    //     const { page, perPage } = params.pagination;
    //     const { field, order } = params.sort;
    //     const query = {
    //         sort: JSON.stringify([field, order]),
    //         range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    //         filter: JSON.stringify({
    //             ...params.filter,
    //             [params.target]: params.id,
    //         }),
    //     };
    //     const url = `${apiUrl}${resource}?${stringify(query)}`;

    //     return httpClient(url).then(({ headers, json }) => ({
    //         data: json,
    //         total: parseInt(headers.get('content-range').split('/').pop(), 10),
    //     }));
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json.results })),

    updateMany: (resource, params) => {
        return Promise.resolve({} as any)
    //     const query = {
    //         filter: JSON.stringify({ id: params.ids}),
    //     };
    //     return httpClient(`${apiUrl}${resource}?${stringify(query)}`, {
    //         method: 'PUT',
    //         body: JSON.stringify(params.data),
    //     }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) =>
        httpClient(`${apiUrl}${resource}`, {
            method: 'POST',
            body: JSON.stringify({ user: params.data }),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.results.id },
        })),

    delete: (resource, params) => {
        return Promise.resolve({} as any)
    //     httpClient(`${apiUrl}${resource}/${params.id}`, {
    //         method: 'DELETE',
    //     }).then(({ json }) => ({ data: json })),
    },

    deleteMany: (resource, params) => {
        return Promise.resolve({} as any)
    //     const query = {
    //         filter: JSON.stringify({ id: params.ids}),
    //     };
    //     return httpClient(`${apiUrl}${resource}?${stringify(query)}`, {
    //         method: 'DELETE',
    //     }).then(({ json }) => ({ data: json }));
    }
};

export default dataProvider;