import { AuthProvider } from "react-admin";
import sdk from "./app-lib/sdk/sdk.service";

const authProvider: AuthProvider = {
    // called when the user attempts to log in
    login: ({ username, password }) => {
        console.log('~~ auth: login')
        return new Promise((res, rej) => {
            sdk.authenticate({
                email: username,
                password,
                remember: true,
            }).then((response) => {
                console.log(response)
                const { access_token } = response.results;
                sdk.setAuthorization(access_token as string);
                localStorage.setItem('access_token', access_token);
                return res('');
            }).catch(e => rej('Nome utente o password errati'))
        })
    },
    // called when the user clicks on the logout button
    logout: () => {
        console.log('~~ auth: logout')
        return new Promise((res, rej) => {
            // sdk.logout().then(() => {
                localStorage.removeItem('access_token');
                return res('');
            // }).catch(e => rej(e))
        })
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        console.log('~~ auth: checkError')
        return new Promise((res, rej) => {
            if (status === 401 || status === 403) {
                localStorage.removeItem('access_token');
                return rej('Si è verificato un errore');
            }
            return res();
        });
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        console.log('~~ auth: checkAuth')
        return localStorage.getItem('access_token')
            ? Promise.resolve()
            : Promise.reject('Utente non autenticato');
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        console.log('~~ auth: getPermissions')
        return Promise.resolve()
    },
};

export default authProvider;