import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import sdk from '../app-lib/sdk/sdk.service';

export const useFileBrowser = (user_id = undefined) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFirstLoaded, setFirstLoaded] = useState(false);

  const loadData = () => {
    setLoading(true);
    return sdk.getFileSystem(user_id)
      .then(res => setFiles(
        {
          id: '',
          isDir: true,
          children: (res.results)
        }
      ))
      // .catch(res => setErrors(res))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    sdk.setAuthorization(token);
    loadData().then(() => setFirstLoaded(true))
  }, [])

  const handleCreateFolder = (newFolder, path) => {
    console.log(newFolder, path)
    const folderPath = path.split('/')
    folderPath.shift()
    // const user_id = folderPath.shift()
    setLoading(true);
    sdk.createFolder({
      user_id,
      files: [{
        name: newFolder,
        path: '/' + (folderPath.join('/') === '' ? '' : folderPath.join('/') + '/'),
      }]
    })
      .then(() => {
        loadData()
      })
      // .catch(res => setErrors(res))
      // .finally(() => setLoading(false))
  }
  // (key: string) => {
  //   setFiles(
  //     files.concat([{
  //       key: key,
  //     }])
  //   )
  //   // this.setState(state => {
  //   //   state.files = state.files.concat([{
  //   //     key: key,
  //   //   }])
  //   //   return state
  //   // })
  // }
  const handleCreateFile = (file, path) => {
    console.log(file, file.data.name, path)
    const folderPath = path.split('/')
    folderPath.shift()
    // const user_id = folderPath.shift()
    setLoading(true);
    return sdk.createFile({
      user_id,
      files: [{
        name: file.data.name,
        data: file.data,
        path: '/' + (folderPath.join('/') === '' ? '' : folderPath.join('/') + '/'),
      }]
    })
      .then(() => {
        loadData();
      })
      // .catch(res => setErrors(res))
      // .finally(() => setLoading(false))

    // const tmp = key.split('/')
    // const user_id = Number(tmp[0])
    // const name = String(tmp[tmp.length - 1])
    // const path = key.substring(key.indexOf('/')+1).replace(name, '')

    // sdk.createFileOrFolder({
    //   user_id,
    //   files: [{
    //     name,
    //     path,
    //   }]
    // })
    //   .then(res => {
    //   })
    //   // .catch(res => setErrors(res))
    //   .finally(() => setLoading(false))


    // const newFiles = _files.map((file) => {
    //   let newKey = prefix
    //   if (prefix !== '' && prefix.substring(prefix.length - 1, prefix.length) !== '/') {
    //     newKey += '/'
    //   }
    //   const invalidChar = ['/', '\\']
    //   if (invalidChar.some(char => file.name.indexOf(char) !== -1)) return
    //   newKey += file.name
    //   return {
    //     key: newKey,
    //     // size: file.size,
    //     // modified: +Moment(),
    //   }
    // })
    // const uniqueNewFiles = []
    // newFiles.forEach((newFile) => {
    //   const exists = files.some((existingFile) => (existingFile.key === newFile.key))
    //   if (!exists) uniqueNewFiles.push(newFile)
    // })
    // const updatedFiles = [...files, uniqueNewFiles]

    // setFiles(files)
  }

  const handleDeleteItem = (path) => {
    console.log((user_id ? '/' + user_id : '') + path);
    setLoading(true)
    sdk.deleteItem((user_id ? '/' + user_id : '') + path)
      .then(() => {
        loadData()
      })
  }

  const handleSendFile = (path) => {
    // console.log('/' + user_id + path)
    // setLoading(true)
    sdk.sendFile('/' + user_id + path)
    .then(() => {
        // TODO: add loading toast
        // loadData()
      })
  }

  // const handleRenameFolder = (oldKey, newKey) => {
  //   this.setState(state => {
  //     const newFiles = []
  //     state.files.map((file) => {
  //       if (file.key.substr(0, oldKey.length) === oldKey) {
  //         newFiles.push({
  //           ...file,
  //           key: file.key.replace(oldKey, newKey),
  //           modified: +Moment(),
  //         })
  //       } else {
  //         newFiles.push(file)
  //       }
  //     })
  //     state.files = newFiles
  //     return state
  //   })
  // }
  // const handleRenameFile = (oldKey, newKey) => {
  //   this.setState(state => {
  //     const newFiles = []
  //     state.files.map((file) => {
  //       if (file.key === oldKey) {
  //         newFiles.push({
  //           ...file,
  //           key: newKey,
  //           modified: +Moment(),
  //         })
  //       } else {
  //         newFiles.push(file)
  //       }
  //     })
  //     state.files = newFiles
  //     return state
  //   })
  // }
  // const handleDeleteFolder = (folderKey) => {
  //   this.setState(state => {
  //     const newFiles = []
  //     state.files.map((file) => {
  //       if (file.key.substr(0, folderKey.length) !== folderKey) {
  //         newFiles.push(file)
  //       }
  //     })
  //     state.files = newFiles
  //     return state
  //   })
  // }
  // const handleDeleteFile = (fileKey) => {
  //   this.setState(state => {
  //     const newFiles = []
  //     state.files.map((file) => {
  //       if (file.key !== fileKey) {
  //         newFiles.push(file)
  //       }
  //     })
  //     state.files = newFiles
  //     return state
  //   })
  // }

  return {
    loading,
    isFirstLoaded,
    files,
    handleCreateFolder,
    handleCreateFile,
    handleDeleteItem,
    handleSendFile,
    // handleRenameFolder,
    // handleRenameFile,
    // handleDeleteFolder,
    // handleDeleteFile,
  }
};

// interface File {
//   fullPath;
//   name;
// }

// interface Folder {
//   fullPath;
//   name;
//   children: (File|Folder)[];
// }

// interface Entry {
//   path;
//   type: 'file' | 'folder';
// }

// interface FileBrowserArgs {
//   currentFolder;
//   files: Entry[],
// }

const ItemGrid = ({ folder, openFolder, openFile, deleteItem, selectedItem, setSelectedItem }) => {
  const folders = Object.entries(folder.children).filter(([_, data]) => data.isDir).map(([_, data], i) => data);
  const files = Object.entries(folder.children).filter(([_, data]) => !data.isDir).map(([_, data], i) => data);

  return (
    <div className="bg-white border rounded p-4">
      {folders.length === 0 && files.length === 0 ? (
        <span className="text-center">La directory è vuota</span>
      ) : (
        <>
          {folders.length !== 0 && (
            <div className="">
              <h2>Cartelle:</h2>
              <div className="row m-0">
                {folders.map((data, i) => (
                  <div
                    key={'folder' + data.id + i}
                    className="col-3 px-1"
                  >
                    <a
                      className={"d-block p-3 user-select-none border rounded" + (data.id === selectedItem ? ' border-info' : '')}
                      onClick={() => setSelectedItem(data.id)}
                      onDoubleClick={() => openFolder(data)}
                    >
                      {data.id}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}

          {files.length !== 0 && (
            <div className="">
              <h2 className="mt-3">File:</h2>
              <div className="row m-0">
                {files.map((data, i) => (
                  <div
                    key={'file' + data.id + i}
                    className="col-3 px-1"
                  >
                    <a
                      className={"d-block p-3 user-select-none border rounded" + (data.id === selectedItem ? ' border-info' : '')}
                      onClick={() => setSelectedItem(data.id)}
                      onDoubleClick={() => openFile(data)}
                    >
                      {data.id}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export const FileBrowser = ({ files, handleCreateFolder, handleCreateFile, handleDeleteItem, customActions }) => {
  const [currentFolder, setCurrentFolder] = useState(files);
  const [currentPath, setCurrentPath] = useState(files.id);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setSelectedItem(null);
  }, [files, currentFolder, currentPath])

  var bla = (path, tmp = files) => {
    if (path.length === 0) {
      // caso base
      return tmp;
    } else {
      // passo induttivo
      var aa = path.shift();
      tmp = tmp.children[aa];
      return bla(path, tmp)
    }
  }

  const gotoPath = (path) => {
    setCurrentPath(path);
    setCurrentFolder(bla(path.split('/')))
  }

  const gotoRoot = () => {
    gotoPath(files.id)
  }

  const goBack = () => {
    const paths = currentPath.split('/');
    paths.pop();
    console.log(currentPath, currentPath.split('/'));
    gotoPath(paths.join('/'));
  }

  useEffect(() => {
    gotoPath(currentPath);
  }, [files]);

  return (
    <div>

      <div className="d-flex justify-content-between">
        <div className="user-select-none mb-3">
          <span
            className="px-3 py-1 mx-1 breadcrumb-item"
            onClick={() => goBack()}
          >{'<'}</span>

          {currentPath.split('/').map((path, i) => (
            <span key={'breadcrumb'+i}>
              <span
                className="px-3 py-1 mx-1 breadcrumb-item"
                onClick={() => {
                  gotoPath(currentPath.split('/').slice(0, i + 1).join('/'))
                }}
              >{path || 'home'}</span>
              <span>/</span>
            </span>
          ))}
        </div>

        <div>
          <span
            className="px-3 py-1 text-primary bg-white border rounded cursor-pointer"
            onClick={() => {
              const folderName = window.prompt('Nome cartella');
              if (folderName !== '') {
                handleCreateFolder(folderName, currentPath)
              }
            }}
          >+ Nuova cartella</span>

          <Formik
            initialValues={(() => {
              return {
                'data': null,
              }
            })()}
            // validationSchema={validationSchema}
            onSubmit={(file) => {
              handleCreateFile(
                file,
                currentPath
              ).then((r) => {
                // console.log(r, currentPath);
                gotoPath(currentPath);
              })
            }}
          >
            {props => (
              <Form
                className="d-inline"
                onSubmit={(e) => {
                  e.preventDefault();
                  props.handleSubmit();
                }}
              >
                <label
                  className="ml-2 px-3 py-1 text-primary bg-white border rounded cursor-pointer"
                  htmlFor="file"
                >+ Carica file</label>
                <input
                  id="file"
                  name="file"
                  type="file"
                  accept="*"
                  onChange={async (event) => {
                    if (event.currentTarget) {
                      const { files } = event.currentTarget;
                      const val = files && files.length > 0 ? files[0] : undefined;
                      props.setFieldValue('data', val)
                      props.handleSubmit()
                    }
                  }}
                  className="form-control"
                  style={{
                    position: 'fixed',
                    left: -1000,
                    top: -1000,
                  }}
                />
              </Form>
            )}
          </Formik>

          {selectedItem && (
            <span
              className="ml-2 px-3 py-1 text-white bg-danger border rounded cursor-pointer"
              onClick={() => {
                window.confirm('Sei sicuro di voler eliminare questo file/cartella? L\'operazione è IRREVERSIBILE') && handleDeleteItem(currentPath + '/' + selectedItem)
              }}
            >Elimina</span>
          )}

          {/* TODO: make generic as "custom action" and make work only for files and not for folders */}
          {selectedItem && customActions && customActions.length > 0 && (
            <span
              className="ml-2 px-3 py-1 text-white bg-primary border rounded cursor-pointer"
              onClick={() => {
                window.confirm('Inviare il file via mail?') && customActions[0].callback(currentPath + '/' + selectedItem)
              }}
            >Invia</span>
          )}
        </div>
      </div>

      <ItemGrid
        folder={currentFolder}
        openFolder={(folder) => {
          gotoPath(currentPath + '/' + folder.id)
        }}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
    </div>
  );
}
